import { FC } from "react";
import { Grid, Typography, styled } from "@material-ui/core";
import { VivacityLogo } from "../Icons/VivacityLogo";
import React from "react";

export const LoginHeader: FC = () => {
  return (
    <Grid container justifyContent="center">
      <Grid container justifyContent="center">
        <SizedVivacityLogo />
      </Grid>
      <HeaderTitle>Stream</HeaderTitle>
      <HeaderSubtitle>
        See the world through the eyes of a Vision Program
      </HeaderSubtitle>
    </Grid>
  );
};

const SizedVivacityLogo = styled(VivacityLogo)(({ theme }) => ({
  height: theme.spacing(10),
  marginRight: theme.spacing(1),
}));

const HeaderTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: "17px",
  fontWeight: 600,
  marginTop: theme.spacing(3),

  marginBottom: theme.spacing(1),
  textAlign: "center",
}));

const HeaderSubtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: "15px",
  fontWeight: 200,
  lineHeight: 1.3,
  textAlign: "center",
}));
