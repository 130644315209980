import React, { FC, SVGAttributes } from "react";

export interface VivacityLogoProps extends SVGAttributes<SVGElement> {
  primary?: string;
  secondary?: string;
}

export const VivacityLogo: FC<SVGAttributes<SVGElement>> = ({ ...rest }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 510.11 510.11"
      enableBackground="new 0 0 510.11 510.11;"
      xmlSpace="preserve"
      {...rest}
    >
      <style type="text/css">
        {`.st0{fill:#A2A9AD;}.st1{fill:#FFFFFF;}.st2{fill:url(#SVGID_1_);}`}
      </style>
      <rect
        x="195.07"
        y="370.81"
        className="st0"
        width="28.06"
        height="92.87"
      />
      <rect x="195.07" y="46.43" className="st0" width="28.06" height="92.87" />
      <path className="st1" d="M137.76,158.34v-0.16V158.34z" />
      <path className="st1" d="M372.22,120.43v-0.16V120.43z" />
      <linearGradient
        id="SVGID_1_"
        gradientUnits="userSpaceOnUse"
        x1="254.9908"
        y1="411.1296"
        x2="254.9908"
        y2="92.8675"
      >
        <stop offset="0.1" stopColor="#93D500" />
        <stop offset="0.1769" stopColor="#80CE1D" />
        <stop offset="0.3746" stopColor="#53BE63" />
        <stop offset="0.5529" stopColor="#2FB09A" />
        <stop offset="0.7053" stopColor="#16A7C2" />
        <stop offset="0.826" stopColor="#06A1DA" />
        <stop offset="0.9" stopColor="#009FE3" />
      </linearGradient>
      <path
        className="st2"
        d="M413.78,316.63l-27.33,60.7v32.17h-27.99v-32.17l-27.8-60.7h28.52l13.07,34.1l13-34.1H413.78z M97.29,363.17
    c0,31.44,16.32,47.96,40.4,47.96c21.69,0,38.61-12.74,40.2-40.33h-28.13c-0.66,11.08-5.37,17.05-12.07,17.05
    c-7.96,0-12.4-7.63-12.4-24.68c0-17.05,4.44-24.68,12.4-24.68c6.7,0,11.41,5.97,12.07,17.05h28.13
    c-1.59-27.66-18.51-40.33-40.2-40.33C113.6,315.21,97.29,331.73,97.29,363.17 M243.73,293.97v22.69h22.69v70.18h27.99v-70.18h22.69
    v-22.69H243.73z M195.07,355.55h28.06v-92.87h-28.06V355.55z M195.07,247.43h28.06v-92.87h-28.06V247.43z M293.11,154.56
    l-12.67,65.47v-0.16v0.16l-12.67-65.47h-29.39l24.15,92.87h35.82l24.14-92.87H293.11z M354.31,92.87l-24.14,92.87h29.39l12.67-65.47
    l12.67,65.47h29.39l-24.15-92.87H354.31z M150.43,92.87l-12.67,65.47l-12.67-65.47H95.7l24.15,92.87h35.82l24.15-92.87H150.43z"
      />
    </svg>
  );
};
