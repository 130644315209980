export type DataChannelSelectOption = {
    label: string
    value: string
    chunkSize: number
    unordered: boolean
    maxPacketLifetimeMs: number
    maxRetransmits: number
    protocol: string
}

export const dataChannelOptions: DataChannelSelectOption[] = [
    {
        value: 'detector_tracker_frames',
        chunkSize: 0,
        // Allow DTFs to arrive out of order, and give up sending after 3 lost frames (since the data will be old anyway)
        unordered: true,
        maxRetransmits: 3,
        maxPacketLifetimeMs: 0,
        protocol: "DetectorTrackerFrame",
        label: 'Detector Tracker Frames'
    },
    {
        label: 'GStreamer control',
        value: 'gstreamer_control',
        chunkSize: 0,
        // The order of these matters
        unordered: false,
        maxRetransmits: 0,
        maxPacketLifetimeMs: 0,
        protocol: "GStreamerControl",
    },
    {
        label: 'Snapshots',
        value: 'snapshot',
        chunkSize: 8000,
        // The order of these matters
        unordered: true,
        maxRetransmits: 10,
        maxPacketLifetimeMs: 0,
        protocol: "WebRTCDataChannelDataChunk",
    },
];