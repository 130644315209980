import { createTheme } from "@material-ui/core/styles";

const SPACING = 6;

export const VivacityTheme = createTheme(
    {
        palette: {
            type: "light",
            primary: {
                light: "#B1DAFF",
                main: "#2397ff",
                dark: "#2397ff",
            },
            secondary: {
                light: "#F9AA33",
                main: "#F9AA33",
                dark: "#F9AA33",
            },
        },
        typography: {
            fontSize: 10,
        },
        spacing: SPACING,
        overrides: {
            MuiInputAdornment: {
                positionEnd: {
                    visibility: "hidden",
                },
            },
            MuiInput: {
                underline: {
                    "&:before": {
                        borderBottom: "unset",
                        transition: "border 0.2s",
                    },
                },
                root: {
                    "&:hover .MuiInputAdornment-positionEnd": {
                        visibility: "visible",
                    },
                },
            },
            MuiOutlinedInput: {
                input: {
                    padding: `${SPACING * 1.7}px ${SPACING * 1.5}px`,
                },
            },
            MuiTab: {
                textColorInherit: {
                    opacity: 1,

                    "&.Mui-disabled": {
                        opacity: 0.25,
                    },
                },
            },
            MuiPaper: {
                elevation1: {
                    boxShadow: "none",
                },
            },
        },
    },
    {
        assetPalette: {
            sensor: "#2397ff",
            countline: "#F9AA33",
            zone: "#9b59b6",
        },
        elementBackground: "#fff",
        buttonHover: "#E4E7E7",
        headerHeight: SPACING * 8,
        navbarHeight: SPACING * 11,
        navbarMargin: SPACING,
        breadcrumbsHeight: SPACING * 5,
        devbarHeight: SPACING * 10,
        tabHeight: SPACING * 5,
        sidebarFooterHeight: SPACING * 45,
        popoverWidth: SPACING * 50,
        popoverHeaderHeight: SPACING * 10,
        popoverFooterHeight: SPACING * 7,
        chatbotButtonHeight: SPACING * 7,
        visionProgramFormWidth: SPACING * 50,
        defaultBorder: "1px solid rgb(236, 239, 241)",
        contentAreaBorder: "1px solid rgba(91,97,110,0.2)",
        containerBorder: "1px solid rgba(0, 0, 0, 0.23)",
        defaultBorderRadius: "5px",
    }
);
