export type VideoSelectOption = {
    label: string
    value: string
    pipelineDefinition: string
}

export const videoOptions: VideoSelectOption[] = [
    {
        value: 'raw_video0',
        label: 'Raw Video Out (default)',
        pipelineDefinition: "src_video0 ! video/x-raw,format=YUY2,width=640,height=360,framerate=0/1 ! videoconvert ! WEBRTC_ENC_H264 ! raw_video0"
    },
    {
        value: 'raw_video1',
        label: 'Raw Video Out for 2nd hw camera)',
        pipelineDefinition: "src_video0 ! video/x-raw,format=YUY2,width=640,height=360,framerate=0/1 ! videoconvert ! WEBRTC_ENC_H264 ! raw_video1"
    },
    {
        value: 'tracking_video0',
        label: 'Tracking Video Out (default)',
        pipelineDefinition: "v4l2src device=/dev/video101 ! video/x-raw,format=YUY2,width=640,height=360 ! videorate ! videoconvert ! video/x-raw,format=I420,framerate=10/1 ! x264enc bframes=0 speed-preset=veryfast key-int-max=60 threads=2 tune=zerolatency ! video/x-h264,stream-format=byte-stream ! tracking_video0"
    },
    {
        value: 'blurred_raw_video0',
        label: 'Blurred Raw Video Out',
        pipelineDefinition: ""
    },
    {
        value: 'blurred_tracking_video0',
        label: 'Blurred Tracking Video Out',
        pipelineDefinition: ""
    }
];
