import React from 'react'
import {configure} from 'mobx';

import {BrowserRouter as Router, Route} from "react-router-dom";
import StoreRoot from "../stores/StoreRoot";
import HomePage from "../pages/HomePage";
import 'bootstrap/dist/css/bootstrap.min.css';
import DeprecatedVideoFrame from '../components/DeprecatedVideoFrame/DeprecatedVideoFrame';
import {inject, observer} from "mobx-react";
import {videoOptions} from "../utils/videoOptions"
import {AuthProvider} from "../providers/Auth.provider";
import {AdminRoute} from "./PrivateRoute";

configure({
  enforceActions: 'observed'
});

@inject("appState")
@observer
class GlobalRouter extends React.Component<{ appState: StoreRoot }> {
  render() {
    return (
      <Router>
        <AuthProvider signallingStore={this.props.appState.SignallingServerConnection}>
          <AdminRoute path="/dashboard" component={HomePage}/>
          <Route path="/video" component={() => (
            <DeprecatedVideoFrame appState={this.props.appState} selectedVideo={videoOptions[2]}/>)}/>
          <AdminRoute path="/video_unblurred" component={() => (
            <DeprecatedVideoFrame appState={this.props.appState} selectedVideo={videoOptions[0]}/>)}/>
        </AuthProvider>
      </Router>
    );
  }
}

export default GlobalRouter;
