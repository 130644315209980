import {
  Configuration,
  FrontendApi,
  IdentityApi,
  RelationshipApi,
} from "@ory/client";
import { ENVIRONMENT, getEnvironment } from "../utils/getEnvironment";

// can't use the nginx proxy for auth requests as it goes through a Docker container
const getOryAuthApiUrl = () => {
  const activeEnvironment = getEnvironment();

  switch (activeEnvironment) {
    case ENVIRONMENT.LOCAL:
      return "http://localhost:4040";
    case ENVIRONMENT.DEV:
      return "https://auth.dev.vivacitylabs.com";
    case ENVIRONMENT.STAGING:
      return "https://auth.staging.vivacitylabs.com";
    case ENVIRONMENT.PROD:
      return "https://auth.vivacitylabs.com";
  }
};

// need to use the nginx proxy for requests to the admin API
const getOryAdminApiUrl = () => {
  const activeEnvironment = getEnvironment();

  switch (activeEnvironment) {
    case ENVIRONMENT.LOCAL:
      return "http://localhost:8888";
    case ENVIRONMENT.DEV:
      return "https://api.auth.dev.vivacitylabs.com";
    case ENVIRONMENT.STAGING:
      return "https://api.auth.staging.vivacitylabs.com";
    case ENVIRONMENT.PROD:
      return "https://api.auth.vivacitylabs.com";
  }
};

export const identityApi = new IdentityApi(
  new Configuration({
    basePath: getOryAdminApiUrl(),
    baseOptions: {
      withCredentials: true,
    },
  })
);

export const frontendApi = new FrontendApi(
  new Configuration({
    basePath: getOryAuthApiUrl(),
    baseOptions: {
      withCredentials: true,
    },
  })
);

export const relationshipApi = new RelationshipApi(
  new Configuration({
    basePath: getOryAdminApiUrl(),
    baseOptions: {
      withCredentials: true,
    },
  })
);
