import React, {RefObject} from "react";
import {VideoID, VideoIDKey} from "../types";
import ReactDOM from "react-dom";
import {inject, observer} from "mobx-react";
import StoreRoot from "../../../stores/StoreRoot";

type VideoRefProviderProps = {
    appState: StoreRoot
    videoIds?: VideoID[]
}

@inject("appState")
@observer
export default class InjectVideoRef extends React.Component<VideoRefProviderProps, {}> {
    private videoRefs: Map<VideoIDKey, RefObject<HTMLVideoElement>> = new Map<VideoIDKey, RefObject<HTMLVideoElement>>();

    forceUpdate(callback?: () => void): void {
        this.videoRefs.forEach((videoRef, videoId) => {
            if (videoRef && videoRef.current && videoId) {
                const stream = this.props.appState.RTC.streamsByID.get(videoId);
                if (stream && videoRef.current.srcObject !== stream) {
                    videoRef.current.srcObject = stream;

                    videoRef.current.play().catch((err) => {
                        console.error("Couldn't start playing video", videoId, err);
                    });
                }
            }
        });
    }

    render() {
        let videoElements = null;
        if (this.props.videoIds) {
            videoElements = this.props.videoIds.map((videoId) => {
                if (this.props.videoIds && this.props.videoIds.length > 0) {
                    this.props.videoIds.forEach((videoId) => {
                        if (!this.videoRefs.get(videoId.toString())) {
                            this.videoRefs.set(videoId.toString(), React.createRef<HTMLVideoElement>());
                        }
                    });
                }
                // React/ React Fiber was really not designed with this use case in mind...
                // How else can I create a bunch of video DOM elements outside of the components which need access to their refs?

                const idString = "-" + videoId.MediaStreamID + videoId.PeerConnectionID;

                return (
                    ReactDOM.createPortal(
                        <video
                            ref={this.videoRefs.get(videoId.toString())}
                            id={"video-ref-video" + idString}
                            style={{display: "none"}}
                            autoPlay={true}
                            muted={true}
                            playsInline={true}>
                        </video>,
                        document.body
                    )
                );
            });
        }
        this.forceUpdate();

        let children = React.Children.toArray(this.props.children).map((child) => {
            return React.cloneElement(child as any, {videoRefs: this.videoRefs, forceVideoUpdate: this.forceUpdate.bind(this)});
        });

        return (
            <React.Fragment>
                {children}
                {videoElements}
            </React.Fragment>
        )
    }
}