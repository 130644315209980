export enum ENVIRONMENT {
  LOCAL,
  DEV,
  STAGING,
  PROD,
}

export const getEnvironment = (): ENVIRONMENT => {
  const hostname = window.location.hostname;
  if (hostname.includes("localhost")) {
    return ENVIRONMENT.LOCAL;
  } else if (hostname.includes("dev.vivacitylabs.com")) {
    return ENVIRONMENT.DEV;
  } else if (hostname.includes("staging.vivacitylabs.com")) {
    return ENVIRONMENT.STAGING;
  } else {
    return ENVIRONMENT.PROD;
  }
};
