import {CircularProgress, Container} from "@material-ui/core";
import React, {FC} from "react";

export const Loading: FC = () => {
  return (
    <div style={{textAlign: "center", marginTop: "25vh"}}>
      <Container>
        <CircularProgress/>
      </Container>
    </div>
  );
};
