let urls = {
  signallingUrl: "webrtc-signalling.vivacitylabs.com"
};
const useProdSignalling = process.env.REACT_APP_USE_PROD_SIGNALLING === "true";

const hostname = window.location.hostname;
if (useProdSignalling) {
  // do nothing
} else if (hostname.includes("localhost")) {
  urls.signallingUrl = "webrtc-signalling.dev.vivacitylabs.com";
} else if (hostname.includes("dev.vivacitylabs.com")) {
  urls.signallingUrl = "webrtc-signalling.dev.vivacitylabs.com";
} else if (hostname.includes("staging.vivacitylabs.com")) {
  urls.signallingUrl = "webrtc-signalling.staging.vivacitylabs.com";
}

export default urls;
