import { inject, observer } from "mobx-react";
import React from "react";
import StoreRoot from "../../stores/StoreRoot";
import { DataChannelSelectOption } from "../../utils/dataChannelOptions";
import { VideoSelectOption } from "../../utils/videoOptions";
import {PeerID, PrincipalID, VideoID, RemotePeer} from "../common/types";
import DetectorTrackerStage from "../DetectorTrackerStage";
import qs from "qs";
import { WebRTCError } from "../../stores/WebRTCPeerConnectionState";
import { Badge } from "reactstrap";

type State = {
    selectedVideo: VideoSelectOption | undefined
    selectedDataChannels: DataChannelSelectOption[]
    visionProgramID: string
    connectionStarted: boolean
    buttonOn: boolean
    width: number
    height: number
}

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

@inject("appState")
@observer
class DeprecatedVideoFrame extends React.Component<{ appState: StoreRoot, selectedVideo: VideoSelectOption }, State> {

    constructor(props: { appState: StoreRoot, selectedVideo: VideoSelectOption }) {
        super(props);

        this.props.appState.RTC.setAutoReconnectEnabled(true);

        const initialAspectRatio = 1080/1920;
        let { width, height } = getWindowDimensions();
        let {width: newWidth, height: newHeight} = this.constrainAspectRatio(width, height, initialAspectRatio);

        this.state = {
            selectedVideo: props.selectedVideo,
            selectedDataChannels: [],
            visionProgramID: "",
            buttonOn: false,
            connectionStarted: false,
            width: newWidth,
            height: newHeight,
        }
        this.props.appState.RTC.on("peer-connection-failure", (principalID: PrincipalID, peerID: PeerID) => {
            let remotePeerSettings = this.props.appState.RemotePeers.get(principalID);
            if (this.state.selectedVideo){
                if (!remotePeerSettings ) {
                   remotePeerSettings = {
                       desiredDataChannels: this.state.selectedDataChannels,
                       desiredVideoTracks: [this.state.selectedVideo.value]
                   }
                }
                this.props.appState.RTC.sendRequestPeerConnectionMessage(principalID, remotePeerSettings.desiredVideoTracks, remotePeerSettings.desiredDataChannels);
            }
        });
        this.props.appState.RTC.on("peer-connection-permanent-failure", (principalID: PrincipalID, peerID: PeerID) => {
            const msg = `Connection to ${principalID} is unavailable or too unstable and has been removed; try using snapshots or a lower quality video feed`;
            const error: WebRTCError = {
                type: "PEER_OFFLINE_OR_UNSTABLE",
                message: msg,
                label: ""
            }
            this.props.appState.RTC.globalErrors.push(error);
            this.props.appState.RemotePeers.delete(principalID);
            this.props.appState.RTC.currentPeerIdByPrincipalId.delete(principalID);
        });

    }

    handleResize() {
        let { width, height } = getWindowDimensions()
        this.setState((prev) => {
            return {
                ...prev,
                width,
                height,
            }
        })
    }

    constrainAspectRatio(width: number, height: number, aspectRatio: number): {width: number, height: number} {
        const newHeight = width * aspectRatio;
        const newWidth = height / aspectRatio;
        if (height/width > aspectRatio) {
            // Shrink height for padding
            height = newHeight;
        } else {
            // Shrink width for padding
            width = newWidth
        }
        return {
            width, height
        }
    }

    async componentDidMount(){
        this.setState(old => {return {...old, status: "Retrieved JWT"}})
        // get vision program ID from query params
        const search = window.location.search;
        var query = qs.parse(search, { ignoreQueryPrefix: true });
        const vpid = query.vpid || "";
        this.setState(
            { visionProgramID: vpid as string}
        );

        // Wait 5 seconds for connection to signalling server to be established
        let tries = 0;
        while (!(this.props.appState.SignallingServerConnection.connectionStatus === "OPEN")){
            await new Promise(resolve =>
            {
                setTimeout(() => {
                    resolve('');
                }, 500)
            });
            tries += 1;
            if (tries >= 10){
                break;
            }
        }
        window.addEventListener("resize", this.handleResize.bind(this))
        // TODO: if connection to signalling server still not open, display error message and tell user to try again

        if (this.props.appState.SignallingServerConnection.connectionStatus !== "OPEN") {
            this.setState((old) => {return { ...old, error:"Could not connect to signalling server. Refresh the page to try again",  }});
            return;
        }
        this.startVisionProgramConnection();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize.bind(this))
    }

    startVisionProgramConnection() {
        // Establish connection
        if (this.state.visionProgramID !== "" && this.props.appState.SignallingServerConnection.connectionStatus === "OPEN") {
            const parsedVpid = parseInt(this.state.visionProgramID, 10)
            const visionProgramString = `vision-program-${this.state.visionProgramID}`
            if (parsedVpid !== undefined && this.state.selectedVideo) {
                const remotePeer: RemotePeer = {
                    desiredDataChannels: this.state.selectedDataChannels,
                    desiredVideoTracks: [this.state.selectedVideo.value]
                }
                this.props.appState.RemotePeers.set(visionProgramString, remotePeer)
                this.props.appState.RTC.sendRequestPeerConnectionMessage(visionProgramString, [this.state.selectedVideo.value], this.state.selectedDataChannels);
            } else {
                console.error("VPID must be a number")
                this.setState((old) => {return { ...old, error: "Vision Program ID must be a number",  }});
            }
        } else if (this.state.visionProgramID === "") {
            this.setState((old) => {return { ...old, error: "Vision Program ID is not set" }});
        }
    }

    render() {
        const zoomableStages: JSX.Element[] = [];

        const activePrincipalIds = [...this.props.appState.RTC.currentPeerIdByPrincipalId.keys()];
        activePrincipalIds.sort().forEach(principalId => {
            const RtcPeerId = this.props.appState.RTC.currentPeerIdByPrincipalId.get(principalId);
            if (!RtcPeerId)
                return;
            const peerConn = this.props.appState.RTC.peerConnections.get(RtcPeerId);
            if (!peerConn)
                return;

            let hasStream = false
            let hasDTF = false
            let hasSnappi = false
            let visionProgramID = -RtcPeerId // negative PeerID (to ensure no clashes with real VPID)
            const videoIDs: VideoID[] = [];

            let aspectRatio = 1080/1920;

            this.props.appState.RTC.streams.forEach((peerStreams, streamPeerID) => {
                if (RtcPeerId === streamPeerID) {
                    peerStreams.forEach((mediaStreams) => {
                        mediaStreams.forEach((mediaStream) => {
                            videoIDs.push(new VideoID(RtcPeerId, mediaStream.id));
                            const tracks = mediaStream.getVideoTracks();
                            if (tracks.length > 0) {
                                const settings = tracks[0].getSettings()
                                if (settings.height && settings.width) {
                                    aspectRatio = settings.height / settings.width;
                                }
                            }
                        });
                    });
                    hasStream = true
                }
            })

            let {width: newWidth, height: newHeight} = this.constrainAspectRatio(this.state.width, this.state.height, aspectRatio);

            this.props.appState.DetectorTrackerFrames.detectorTrackerChannels.forEach((peerStreams, dtfChannelPeerID) => {
                if (RtcPeerId === dtfChannelPeerID) {
                    let maybeVisionProgramID = this.props.appState.DetectorTrackerFrames.visionProgramIDsByPeerID.get(RtcPeerId);
                    if (maybeVisionProgramID === undefined) {
                        maybeVisionProgramID = -RtcPeerId // negative PeerID (to ensure no clashes with real VPID)
                    }
                    visionProgramID = maybeVisionProgramID
                    hasDTF = true
                }
            })
            this.props.appState.DetectorTrackerFrames.imageSnapshotChannels.forEach((peerStreams, snapshotChannelPeerID) => {
                if (RtcPeerId === snapshotChannelPeerID) {
                    hasSnappi = true
                }
            })

            if (this.props.appState.DetectorTrackerFrames.visionProgramIDsByPeerID.get(RtcPeerId) === undefined) {
                this.props.appState.DetectorTrackerFrames.visionProgramIDsByPeerID.set(RtcPeerId, visionProgramID);
                this.props.appState.DetectorTrackerFrames.peerIDsByVisionProgramID.set(visionProgramID, RtcPeerId);
            }

            if (hasStream || hasDTF || hasSnappi) {
                zoomableStages.push((
                    <DetectorTrackerStage
                        key={RtcPeerId}
                        availableVideoIDs={videoIDs.filter((videoID) => videoID.PeerConnectionID === RtcPeerId)}
                        appState={this.props.appState}
                        visionProgramID={visionProgramID}
                        width={newWidth}
                        height={newHeight}
                        showControls={false}
                        hideVisionProgramId={true}
                    />
                ));
            }

        });

        return (
            <div>
                {this.props.appState.SignallingServerConnection.connectionStatus !== "OPEN" && <Badge color={"danger"}>Sensor not responding</Badge>}
                {zoomableStages.map(s => s)}
            </div>
        );
    }
}

export default DeprecatedVideoFrame;
