import { DataChannelSelectOption } from "../../../utils/dataChannelOptions";


export type VideoIDKey = string

export class VideoID {
    PeerConnectionID: string;
    MediaStreamID: string;
    constructor(peerConnectionID: string, mediaStreamID: string) {
        this.PeerConnectionID = peerConnectionID;
        this.MediaStreamID = mediaStreamID;
    }

    toString(): VideoIDKey {
        return JSON.stringify(this)
    }
}

export type PeerID = string;
export type PrincipalID = string;

export type RemotePeer = {
    desiredDataChannels: DataChannelSelectOption[],
    desiredVideoTracks: string[]
}