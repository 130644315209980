import { Button, ButtonProps, styled } from "@material-ui/core";
import React from "react";

export const StandardButton = styled(({ variant, ...rest }: ButtonProps) => (
  <Button variant={variant ?? "contained"} {...rest} />
))(({ color }: { color?: string }) => ({
  color: color ?? "primary",
  textTransform: "none",
  fontSize: "13px",
  padding: "5px 20px",
}));
