import React, {useContext} from 'react';
import {Route, Redirect} from 'react-router-dom';
import {RouteProps} from "react-router";
import { AuthContext } from "../providers/Auth.provider";


export const AdminRoute = ({component: Component, ...rest}: RouteProps) => {
  const { isSessionVivacityAdmin } = useContext(AuthContext);
  if (!Component) return null;
  return (
    <Route
      {...rest}
      render={(props) =>
        isSessionVivacityAdmin ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login"/>
        )
      }
    />
  )
};