import {inject, observer} from "mobx-react";
import {Badge, Card, Table} from "reactstrap";
import React from "react";
import StoreRoot from "../../stores/StoreRoot";

type DebugProps = {
    peerId: string
    appState: StoreRoot
}

type TrackInfo = {
    trackLabel: string
    id: string
    kind: string
    readyState: string
    active: boolean
    enabled: boolean
    isolated: boolean
    muted: boolean
}

function getColourFromState(state: string): string {
    let colour: string;
    if (state === "connected" || state === "stable" || state === "complete" || state === "open" || state === "live") {
        colour = "success";
    } else if (state === "new") {
        colour = "primary";
    } else if (state === "connecting" || "gathering") {
        colour = "warning";
    } else {
        // "disconnected" | "failed" | "closed"
        colour = "danger";
    }
    return colour
}

function getBadgeWithState(state: string | undefined, key: string): JSX.Element | null {
    if (state === undefined) {
        return null
    } else {
        return (
            <h5 key={key}>
                <Badge key={key} color={getColourFromState(state)}>
                    {state}
                </Badge>
            </h5>
        )
    }
}

const PeerConnectionDebugInfo: React.FunctionComponent<DebugProps> = inject("appState")(observer((props: DebugProps) => {
    let localPeerConnState = getBadgeWithState(props.appState.RTC.localPeerConnectionState.get(props.peerId), props.peerId+ "-local-peer-conn-state");
    let remotePeerConnState = getBadgeWithState(props.appState.RTC.remotePeerConnectionState.get(props.peerId), props.peerId+ "-remote-peer-conn-state");
    let localSignallingState = getBadgeWithState(props.appState.RTC.localSignallingState.get(props.peerId), props.peerId+ "-local-signalling-state");
    let remoteSignallingState = getBadgeWithState(props.appState.RTC.remoteSignallingState.get(props.peerId), props.peerId+ "-remote-signalling-state");
    let localIceGathererState = getBadgeWithState(props.appState.RTC.localIceGathererState.get(props.peerId), props.peerId+ "-local-ice-gatherer-state");
    let remoteIceGathererState = getBadgeWithState(props.appState.RTC.remoteIceGathererState.get(props.peerId), props.peerId+ "-remote-ice-gatherer-state");
    let localIceConnectionState = getBadgeWithState(props.appState.RTC.remoteIceConnectionState.get(props.peerId), props.peerId+ "-local-ice-connection-state");
    let remoteIceConnectionState = getBadgeWithState(props.appState.RTC.remoteIceConnectionState.get(props.peerId), props.peerId+ "-remote-ice-connection-state");

    let localDataChannelState = null;
    let remoteDataChannelState = null;

    let trackState = null;

    let localErrors = null;
    let remoteErrors = null;

    const localErrorsProto = props.appState.RTC.localErrors.get(props.peerId);
    if (localErrorsProto !== undefined) {
        localErrors = localErrorsProto.map((err) => {
            return (<Badge color={"danger"}>{err.type + ": " + err.message}</Badge>)
        });
    }

    const remoteErrorsProto = props.appState.RTC.remoteErrors.get(props.peerId);
    if (remoteErrorsProto !== undefined) {
        remoteErrors = remoteErrorsProto.map((err) => {
            return (<Badge color={"danger"}>{err.type + ": " + err.message}</Badge>)
        });
    }

    const localDataChannelStateMap = props.appState.RTC.localDataChannelState.get(props.peerId);
    if (localDataChannelStateMap !== undefined) {
        const labelStates: {dataChannelState: string, dataChannelLabel: string}[] = [];
        localDataChannelStateMap.forEach((dataChannelState, dataChannelLabel) => {
            labelStates.push({dataChannelState, dataChannelLabel})
        });

        localDataChannelState = labelStates.map((labelState) => {
            return (
                <h5 key={props.peerId+"-local-data-channel-"+labelState.dataChannelLabel}>
                    <Badge color={getColourFromState(labelState.dataChannelState)} key={props.peerId+"-local-data-channel-"+labelState.dataChannelLabel}>
                        Data Channel "{labelState.dataChannelLabel}" {labelState.dataChannelState}
                    </Badge>
                </h5>
            )
        })
    }

    const remoteDataChannelStateMap = props.appState.RTC.remoteDataChannelState.get(props.peerId);
    if (remoteDataChannelStateMap !== undefined) {
        const labelStates: {dataChannelState: string, dataChannelLabel: string}[] = [];
        remoteDataChannelStateMap.forEach((dataChannelState, dataChannelLabel) => {
            labelStates.push({dataChannelState, dataChannelLabel})
        });
        remoteDataChannelState =  labelStates.map((labelState) => {
            return (
                <h5 key={props.peerId+"-remote-data-channel-"+labelState.dataChannelLabel}>
                    <Badge color={getColourFromState(labelState.dataChannelState)} key={props.peerId+"-remote-data-channel-"+labelState.dataChannelLabel}>
                        Data Channel "{labelState.dataChannelLabel}" {labelState.dataChannelState}
                    </Badge>
                </h5>
            )
        })
    }

    const localTrackStateMap = props.appState.RTC.streams.get(props.peerId);
    if (localTrackStateMap !== undefined) {
        const labelStates: TrackInfo[] = [];

        localTrackStateMap.forEach((mediaStreams, trackLabel) => {
            mediaStreams.forEach((mediaStream) => {
                mediaStream.getTracks().forEach((track) => {
                    labelStates.push({
                        active: mediaStream.active,
                        enabled: track.enabled,
                        id: track.id,
                        // @ts-ignore
                        isolated: track.isolated,
                        kind: track.kind,
                        muted: track.muted,
                        readyState: track.readyState,
                        trackLabel: trackLabel,
                    });
                });
            })
        });

        trackState = labelStates.map((labelState) => {
            return (
                <div key={props.peerId + "-track-" + labelState.trackLabel}>
                    <h5>
                        <Badge color={getColourFromState(labelState.readyState)}>
                            Track "{labelState.trackLabel}" {labelState.readyState}
                        </Badge>
                    </h5>
                    {JSON.stringify(labelState, null, 2)}
                </div>

            )
        })
    }

    return (
        <Card key={props.peerId}>
            <h4>Peer Connection {props.peerId}  <Badge color={"info"}>{props.appState.RTC.wasOfferer.get(props.peerId) ? "Offerer" : "Answerer"}</Badge></h4>

            <Table>
                <thead>
                <tr>
                    <th>State</th>
                    <th>Local</th>
                    <th>Remote</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <th scope="row">Errors</th>
                    <td>{localErrors}</td>
                    <td>{remoteErrors}</td>
                </tr>
                <tr>
                    <th scope="row">Tracks</th>
                    <td>{trackState}</td>
                    <td></td>
                </tr>
                <tr>
                    <th scope="row">Data Channels</th>
                    <td>{localDataChannelState}</td>
                    <td>{remoteDataChannelState}</td>
                </tr>
                <tr>
                    <th scope="row">Peer Connection State</th>
                    <td>{localPeerConnState}</td>
                    <td>{remotePeerConnState}</td>
                </tr>
                <tr>
                    <th scope="row">Signalling State</th>
                    <td>{localSignallingState}</td>
                    <td>{remoteSignallingState}</td>
                </tr>
                <tr>
                    <th scope="row">ICE Gatherer State</th>
                    <td>{localIceGathererState}</td>
                    <td>{remoteIceGathererState}</td>
                </tr>
                <tr>
                    <th scope="row">ICE Connection State</th>
                    <td>{localIceConnectionState}</td>
                    <td>{remoteIceConnectionState}</td>
                </tr>
                </tbody>
            </Table>
        </Card>
    );
}));

export default PeerConnectionDebugInfo;
