import React, { FC, useState } from "react";
import { LoginFlow } from "@ory/client";
import { GoogleLogo } from "../Icons/GoogleLogo";
import { StandardButton } from "../StandardButton";
import { CircularProgress, styled } from "@material-ui/core";

interface GoogleLoginButtonProps {
  loginFlow: LoginFlow;
  redirectTo?: string;
}

export const GoogleLoginButton: FC<GoogleLoginButtonProps> = ({
  loginFlow,
  redirectTo,
}) => {
  let redirectUrl = `${encodeURIComponent(window.origin)}`;
  if (redirectTo) {
    redirectUrl = redirectUrl + redirectTo;
  }
  const [isLoading, setIsLoading] = useState(false);
  return (
    <form
      action={`${loginFlow.ui.action}&return_to=${redirectUrl}`}
      method={loginFlow.ui.method}
      onSubmit={() => setIsLoading(true)}
      onAbort={() => setIsLoading(false)}
      aria-disabled={isLoading}
    >
      <StandardButton
        className="login-with-google-btn"
        style={{
          marginTop: 36,
          cursor: isLoading ? "not-allowed" : "pointers",
          pointerEvents: isLoading ? "none" : "auto",
          color: isLoading ? "grey" : "primary",
          border: isLoading ? "1px solid grey" : "1px solid primary",
        }}
        type="submit"
        name="provider"
        value="google"
        variant="outlined"
        fullWidth
      >
        {isLoading ? (
          <CircularProgress size={20} />
        ) : (
          <SizedGoogleLogo />
        )}
        Sign in with Google
      </StandardButton>
      <input
        type="hidden"
        name="upstream_parameters.hd"
        value="vivacitylabs.com"
      />
    </form>
  );
};

const SizedGoogleLogo = styled(GoogleLogo)(({ theme }) => ({
  height: theme.spacing(3),
  marginRight: theme.spacing(1),
}));
