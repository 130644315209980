import {FC} from "react";
import {Grid, Typography} from "@material-ui/core";
import {styled} from "@material-ui/styles";
import {LoginHeader, GoogleLoginButton} from "../components/Login";
import {LoginFlow} from "@ory/client";
import React from "react";

interface LoginPageProps {
  loginFlow: LoginFlow;
}

export const LoginPage: FC<LoginPageProps> = ({loginFlow}) => (
  <FullSizeGrid container>
    <FullHeightGrid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <LoginContainer>
        <LoginHeader/>
        <FormContainer container direction="column">
          <GoogleLoginButton
            loginFlow={loginFlow}
            redirectTo={encodeURIComponent(window.location.toString())}
          />
        </FormContainer>
      </LoginContainer>
      <Copyright variant="subtitle2">
        © 2024 VivaCity Labs | &nbsp;
        <a
          href="https://vivacitylabs.com/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms and Conditions
        </a>
      </Copyright>
    </FullHeightGrid>
  </FullSizeGrid>
);


const FullHeightGrid = styled(Grid)({
  height: "100%",
});

const FullSizeGrid = styled(Grid)({
  height: "100vh",
  width: "100%",
});

const LoginContainer = styled(Grid)(({theme}) => ({
  width: "400px",
  border: "1px solid #e0e0e0",
  // padding: theme.spacing(6),
  // borderRadius: theme.spacing(1),
}));

const FormContainer = styled(Grid)(() => ({
  width: "100%",
}));

const Copyright = styled(Typography)(({theme}) => ({
  // bottom: theme.spacing(3),
  // color: theme.palette.text.secondary,
  // marginTop: theme.spacing(3),
}));
