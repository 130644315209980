import React, { FC, createContext } from "react";
import { LoginFlow, LogoutFlow, Session } from "@ory/client";
import { useAuth } from "../hooks/useAuth.hook";
import { LoginPage } from "../pages/Login.page";
import { Loading } from "../components/Loading";
import SignallingServerConnection from "../stores/SignallingServerConnection";

interface ParentProps {
  children: React.ReactNode;
  signallingStore: SignallingServerConnection;
}

export const AuthProvider: FC<ParentProps> = ({ children, signallingStore }) => {
  const { session, loginFlow, logoutFlow, isSessionVivacityAdmin, sessionJwt } = useAuth(signallingStore);

  if (!!session && !!sessionJwt) {
    return (
      <AuthContext.Provider
        value={{ session, logoutFlow, isSessionVivacityAdmin }}
      >
        {children}
      </AuthContext.Provider>
    );
  }

  // not signed in, show login page
  if (loginFlow) {
    return <LoginPage loginFlow={loginFlow} />;
  }

  // loading login flow
  return <Loading />;
};

interface AuthContextType {
  session?: Session;
  loginFlow?: LoginFlow;
  logoutFlow?: LogoutFlow;
  isSessionVivacityAdmin?: boolean;
}

export const AuthContext = createContext<AuthContextType>({});
