import {inject, observer} from "mobx-react";
import React, {Fragment} from "react";
import StoreRoot from "../../stores/StoreRoot";
import {Button, CustomInput, Form, FormGroup, Label} from "reactstrap";
import Select from "react-select";
import InjectVideoRef from "../common/InjectVideoRef";
import ZoomableStage from "../common/pixi/ZoomableStage";
import ContainerWithBackground from "../common/pixi/ContainerWithBackground";
import DetectorTrackerFrameView from "../common/DetectorTrackerFrameView";
import {VideoID} from "../common/types";
import {WebRTCDataChannelDataChunk} from "../../vivacity/core/webrtc_peer_connector_pb";

import {Table} from 'reactstrap';

type AlertEvent = {
    zoneId: number
    alertType: string
    alertState: string
    currentValue: number
}

type DetectorTrackerStageProps = {
    appState: StoreRoot
    visionProgramID: number
    availableVideoIDs: VideoID[]
    height: number
    width: number
    stageHeight?: number
    stageWidth?: number
    showControls: boolean
    hideVisionProgramId?: boolean
}

type DetectorTrackerStageState = {
    showBoxes: boolean
    showLabels: boolean
    showOccupancyZonePoints: boolean
    showOccupancyZones: boolean
    showPredicted: boolean
    showOnlyStopped: boolean
    showAlerts: boolean
    backgroundBlur: number
    backgroundAlpha: number
    selectedVideo?: {
        value: string
        label: string
    }
}
@inject("appState")
@observer
export default class DetectorTrackerStage extends React.Component<DetectorTrackerStageProps, DetectorTrackerStageState> {
    constructor(props: DetectorTrackerStageProps) {
        super(props);
        this.state = {
            showBoxes: true,
            showLabels: true,
            showOccupancyZonePoints: true,
            showOccupancyZones: true,
            showPredicted: true,
            showOnlyStopped: false,
            showAlerts: false,
            backgroundAlpha: 1,
            backgroundBlur: 0,
            selectedVideo: this.props.availableVideoIDs.length > 0 ? {
                label: this.props.availableVideoIDs[0].MediaStreamID,
                value: this.props.availableVideoIDs[0].toString()
            } : undefined,
        }
    }

    handleSelectVideoChange(selectedVideo: any) {
        this.setState({selectedVideo})
    }

    render() {
        let videoID = undefined;

        if (this.state.selectedVideo) {
            videoID = this.state.selectedVideo.value;
        }

        let backgroundAdjustmentControls = null;

        let maybeImage: HTMLImageElement | undefined;
        const peerID = this.props.appState.DetectorTrackerFrames.peerIDsByVisionProgramID.get(this.props.visionProgramID);

        // Only show an image if there's no video
        if (!videoID && peerID) {
            maybeImage = this.props.appState.DetectorTrackerFrames.latestSnapshots.get(peerID);
        }
        const stageWidth = this.props.stageWidth ? this.props.stageWidth : this.props.width;
        const stageHeight = this.props.stageHeight ? this.props.stageHeight : this.props.height;

        if ((this.props.availableVideoIDs.length > 0 || (peerID && this.props.appState.DetectorTrackerFrames.imageSnapshotChannels.has(peerID))) && this.props.appState.SignallingServerConnection.jwtName !== "tfgm-guest" && this.props.appState.SignallingServerConnection.jwtName !== "demo-guest") {
            backgroundAdjustmentControls = (
                <Fragment>
                    <FormGroup
                        style={{display: !videoID && peerID && this.props.appState.DetectorTrackerFrames.imageSnapshotChannels.has(peerID) ? "none" : "block"}}>
                        <Label>
                            Video Background
                            <Select
                                value={this.state.selectedVideo}
                                onChange={this.handleSelectVideoChange.bind(this)}
                                options={this.props.availableVideoIDs.map(videoID => {
                                    return {
                                        label: videoID.MediaStreamID,
                                        value: videoID.toString(),
                                    }
                                })}
                                placeholder={"Select Video Background"}
                            />
                        </Label>

                    </FormGroup>

                    <FormGroup>
                        <Label for="backgroundAlpha">Background Alpha</Label>
                        <CustomInput
                            type="range"
                            min={0}
                            max={1}
                            step={0.01}
                            value={this.state.backgroundAlpha}
                            id={"backgroundAlpha-" + this.props.visionProgramID}
                            name="backgroundAlpha"
                            onChange={(e) => {
                                this.setState({backgroundAlpha: parseFloat(e.target.value)})
                            }}
                        />
                        <Label for="backgroundBlur">Background Blur</Label>
                        <CustomInput
                            min={0}
                            max={10}
                            step={0.1}
                            value={this.state.backgroundBlur}
                            type="range" id={"backgroundBlur-" + this.props.visionProgramID}
                            name="backgroundBlur"
                            onChange={(e) => {
                                this.setState({backgroundBlur: parseFloat(e.target.value)})
                            }}
                        />
                    </FormGroup>
                </Fragment>
            )
        }

        let alerts = null;
        if (this.state.showAlerts) {
            const zoneAlerts: AlertEvent[][] = [];
            const alertsByZoneId = this.props.appState.DetectorTrackerFrames.zonalAlerts.get(this.props.visionProgramID);
            if (alertsByZoneId) {
                alertsByZoneId.forEach((alerts, zoneId) => {
                    const rows: AlertEvent[] = [];
                    alerts.forEach((alertState, alertType) => {
                        let currentValue: number = 0;
                        if (alertType === "STOPPED_VEHICLE_ALERT") {
                            const numberStopped = this.props.appState.DetectorTrackerFrames.latestStoppedVehiclesByZoneID.get(zoneId);
                            currentValue = numberStopped ? numberStopped : 0
                        } else if (alertType === "SPEED_ALERT") {
                            let distanceScalar = this.props.appState.DetectorTrackerFrames.latestAverageDistanceScalarByZoneID.get(zoneId);
                            currentValue = distanceScalar ? Math.round(distanceScalar * 100) / 100 : 0
                        } else if (alertType === "FLOW_ALERT") {
                            let anticlockwiseCrossing = this.props.appState.DetectorTrackerFrames.latestCrossingsAnticlockwiseByZoneID.get(zoneId);
                            let clockwiseCrossing = this.props.appState.DetectorTrackerFrames.latestCrossingsClockwiseByZoneID.get(zoneId);
                            currentValue = (anticlockwiseCrossing ? anticlockwiseCrossing : 0) + (clockwiseCrossing ? clockwiseCrossing : 0)
                        } else if (alertType === "OCCUPANCY_ALERT") {
                            let currentOccupancy = this.props.appState.DetectorTrackerFrames.latestOccupanciesByZoneID.get(zoneId);
                            currentValue = currentOccupancy ? currentOccupancy : 0
                        }
                        rows.push({zoneId, alertType, alertState, currentValue});
                    });
                    zoneAlerts.push(rows);
                })
            }


            alerts =
                zoneAlerts.map(zone => (
                    <div key={zone[0].zoneId}>
                        <Table bordered size="sm" responsive>
                            <thead>
                            <tr>
                                <td align="center">Zone Id</td>
                                <td align="center">Alert Type</td>
                                <td align="center">Alert State</td>
                                <td align="center">Current Feature Value</td>
                            </tr>
                            </thead>
                            <tbody>
                            {zone.map(row => (
                                <tr key={row.zoneId + row.alertType} style={{backgroundColor: row.alertState === "TRIGGER" ? "red" : (row.alertState === "CLEAR" ? "lightgreen" : "")}}>
                                    <td align="center">{row.zoneId}</td>
                                    <td align="center">{row.alertType}</td>
                                    <td align="center">{row.alertState}</td>
                                    <td align="center">{row.currentValue}</td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </div>
                ))
        }

        return (
            <Fragment key={this.props.visionProgramID}>
                <Form style={{width: stageWidth + "px", display: this.props.showControls ? "block": "None"}}>
                    {backgroundAdjustmentControls}
                    <FormGroup check inline={!this.props.appState.DetectorTrackerFrames.compactMode}>
                        <CustomInput
                            type="switch"
                            id={"showBoxesSwitch-" + this.props.visionProgramID}
                            name="showBoxesSwitch"
                            label="Show Boxes"
                            inline
                            defaultChecked
                            onChange={(e) => {
                                this.setState((prevState) => {
                                    return {
                                        showBoxes: !prevState.showBoxes
                                    }
                                })
                            }}
                        />
                        <CustomInput
                            type="switch"
                            id={"showLabelsSwitch-" + this.props.visionProgramID}
                            name="showLabelsSwitch"
                            label="Show Labels"
                            inline
                            defaultChecked
                            onChange={(e) => {
                                this.setState((prevState) => {
                                    return {
                                        showLabels: !prevState.showLabels
                                    }
                                })
                            }}
                        />
                        <CustomInput
                            type="switch"
                            id={"showOccupancyZonePoints-" + this.props.visionProgramID}
                            name="showOccupancyZonePoints"
                            label="Show Points"
                            inline
                            defaultChecked
                            onChange={(e) => {
                                this.setState((prevState) => {
                                    return {
                                        showOccupancyZonePoints: !prevState.showOccupancyZonePoints
                                    }
                                })
                            }}
                        />
                        <CustomInput
                            type="switch"
                            id={"showOccupancyZones-" + this.props.visionProgramID}
                            name="showOccupancyZones"
                            label="Show Zones"
                            inline
                            defaultChecked
                            onChange={(e) => {
                                this.setState((prevState) => {
                                    return {
                                        showOccupancyZones: !prevState.showOccupancyZones
                                    }
                                })
                            }}
                        />
                        <CustomInput
                            type="switch"
                            id={"showOnlyStopped-" + this.props.visionProgramID}
                            name="showOnlyStopped"
                            label="Show Only Stopped"
                            inline
                            onChange={(e) => {
                                this.setState((prevState) => {
                                    return {
                                        showOnlyStopped: !prevState.showOnlyStopped
                                    }
                                })
                            }}
                        />
                        <CustomInput
                            type="switch"
                            id={"showPredicted-" + this.props.visionProgramID}
                            name="showPredicted"
                            label="Show Predicted"
                            inline
                            defaultChecked
                            onChange={(e) => {
                                this.setState((prevState) => {
                                    return {
                                        showPredicted: !prevState.showPredicted
                                    }
                                })
                            }}
                        />
                        <CustomInput
                            type="switch"
                            id={"showAlerts-" + this.props.visionProgramID}
                            name="showAlerts"
                            label="Show Alerts"
                            inline
                            onChange={(e) => {
                                this.setState((prevState) => {
                                    return {
                                        showAlerts: !prevState.showAlerts
                                    }
                                })
                            }}
                        />
                        <Button
                            color={"primary"}
                            style={{
                                display: !videoID && peerID && this.props.appState.DetectorTrackerFrames.imageSnapshotChannels.has(peerID) ? "block" : "none",
                                marginBottom: "10px"
                            }}
                            onClick={() => {
                                if (peerID) {
                                    const snapshotChannel = this.props.appState.DetectorTrackerFrames.imageSnapshotChannels.get(peerID);
                                    if (snapshotChannel) {
                                        const emptyChunk = new WebRTCDataChannelDataChunk();
                                        emptyChunk.setTotalFragments(1);
                                        emptyChunk.setId(1);

                                        // Doesn't really matter what we put in here
                                        emptyChunk.setData("gief_msg_plz");

                                        snapshotChannel.send(emptyChunk.serializeBinary());
                                    }
                                }
                            }}>
                            Refresh Snapshot
                        </Button>
                    </FormGroup>
                </Form>
                <span>{!this.props.hideVisionProgramId && "vision-program-"+this.props.visionProgramID.toString()}</span>
                <InjectVideoRef appState={this.props.appState} videoIds={this.props.availableVideoIDs}
                                key={this.props.visionProgramID}>
                    <ZoomableStage
                        style={{width: stageWidth + "px", height: stageHeight + "px"}}
                        options={{
                            antialias: true,
                            backgroundColor: 0xEEEEEE,
                            width: this.props.width,
                            height: this.props.height
                        }}
                    >
                        <ContainerWithBackground
                            appState={this.props.appState}
                            backgroundBlur={this.state.backgroundBlur}
                            backgroundAlpha={this.state.backgroundAlpha}
                            x={0}
                            y={0}
                            width={this.props.width}
                            height={this.props.height}
                            videoID={videoID}
                            image={maybeImage}
                        >
                            <DetectorTrackerFrameView
                                appState={this.props.appState}
                                visionProgramID={this.props.visionProgramID}
                                showBoxes={this.state.showBoxes}
                                showLabels={this.state.showLabels}
                                showOccupancyPoints={this.state.showOccupancyZonePoints}
                                showOccupancyZones={this.state.showOccupancyZones}
                                showOnlyStopped={this.state.showOnlyStopped}
                                showPredicted={this.state.showPredicted}
                            />
                        </ContainerWithBackground>
                    </ZoomableStage>
                </InjectVideoRef>
                <div>
                    <Button
                        color={"primary"}
                        style={{
                            display: !videoID && peerID &&
                            this.props.appState.DetectorTrackerFrames.imageSnapshotChannels.has(peerID) &&
                            this.props.appState.DetectorTrackerFrames.compactMode &&
                            !this.props.appState.DetectorTrackerFrames.showControls ? "block" : "none",
                            marginBottom: "10px"
                        }}
                        onClick={() => {
                            if (peerID) {
                                const snapshotChannel = this.props.appState.DetectorTrackerFrames.imageSnapshotChannels.get(peerID);
                                if (snapshotChannel) {
                                    const emptyChunk = new WebRTCDataChannelDataChunk();
                                    emptyChunk.setTotalFragments(1);
                                    emptyChunk.setId(1);

                                    // Doesn't really matter what we put in here
                                    emptyChunk.setData("gief_msg_plz");

                                    snapshotChannel.send(emptyChunk.serializeBinary());
                                }
                            }
                        }}>
                        Refresh Snapshot
                    </Button>
                </div>
                <div>
                    {this.state.showAlerts ? (<h2 id='title'>Alerts</h2>) : null}
                    {alerts}
                </div>
            </Fragment>
        );
    }
}