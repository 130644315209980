import { makeStyles } from "@material-ui/core/styles";

import { animationsStyles } from "./animations.styles";

export const useGlobalStyles = makeStyles({
    "@global": {
        body: {
            fontFamily: "'Roboto', sans-serif",
        },

        "html, body, #root": {
            height: "100vh",
        },

        "#root": {
            background: "#FBFBFB",
        },

        a: {
            textDecoration: "none",
            color: "inherit",

            "&:hover, &:focus, &:visited": {
                color: "inherit",
                textDecoration: "none",
            },
        },

        ...animationsStyles,
    },
});