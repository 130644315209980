import React from 'react'
import './App.css';
import {configure} from 'mobx';
import StoreRoot from "./stores/StoreRoot";
import {Provider} from 'mobx-react';
import {ThemeProvider} from "@material-ui/core/styles";

import GlobalRouter from "./routers/globalRouter";
import 'bootstrap/dist/css/bootstrap.min.css';
import {VivacityTheme} from "./styles";

configure({
    enforceActions: 'observed'
});

const stores = new StoreRoot();
(window as any).stores = stores;

class App extends React.Component {

    render() {
        return (
            <ThemeProvider theme={VivacityTheme}>
                <Provider appState={stores}>
                    <GlobalRouter appState={stores}/>
                </Provider>
            </ThemeProvider>
        );
    }
}

export default App;
